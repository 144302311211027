import type { FC, ReactNode } from 'react'
import type { BoxProps } from '@chakra-ui/react'
import { Box, Flex, VStack } from '@chakra-ui/react'
import { BiCart } from 'react-icons/bi'
import { GrandNextLink, GrandPopover, GrandText } from '../../common'

export interface ICartButtonProps extends BoxProps {
  badgeCount: number
  isOpen: boolean
  isHidden: boolean
  children: ReactNode | ReactNode[]
  path: string
}

export const CartButton: FC<ICartButtonProps> = ({
  path,
  children,
  isOpen,
  badgeCount,
  isHidden,
  ...props
}) => {
  if (isHidden) {
    return null
  }

  return (
    <GrandPopover
      {...{ isOpen }}
      contentProps={{
        _focus: {
          outlineColor: 'none'
        }
      }}
      target={
        <Box {...props}>
          <Box position="relative">
            {badgeCount > 0 && (
              <Flex
                align="center"
                bg="status.warning"
                borderRadius="50%"
                cursor="default"
                fontSize="xs"
                h={5}
                justify="center"
                position="absolute"
                right="-60%"
                top="-60%"
                w={5}
                zIndex={101}>
                <GrandText textAlign="center">{badgeCount}</GrandText>
              </Flex>
            )}

            <GrandNextLink
              alignItems="center"
              aria-label="add to cart"
              href={path}
              justifyContent="center"
              shadow="none"
              size="sm">
              <BiCart size={22} />
            </GrandNextLink>
          </Box>
        </Box>
      }
      title="">
      <VStack px={2}>{children}</VStack>
    </GrandPopover>
  )
}
