import type { FC, ReactElement } from 'react'
import type { StackProps } from '@chakra-ui/react'
import { HStack, VStack } from '@chakra-ui/react'
import type { LinkProps } from '@chakra-ui/next-js'
import type { SocialMedia, SocialMediaItem } from 'ecosystem'
import {
  FaFacebook,
  FaInstagram,
  FaLinkedin,
  FaMeta,
  FaThreads,
  FaTiktok,
  FaX
} from 'react-icons/fa6'
import GrandHeading from '../../common/GrandHeading'
import GrandNextLink from '../../common/GrandNextLink'
import { useOverridesContext } from '../../lib/overrides/hooks'
import { overrideText } from '../../lib/overrides'

interface IFooterSocialMediaProps extends StackProps {
  items: SocialMediaItem<LinkProps>[]
}

export interface FooterSocialMediaOverrides {
  followUs?: string
}

const FooterSocialMedia: FC<IFooterSocialMediaProps> = ({ items, ...props }) => {
  const overrides = useOverridesContext<keyof FooterSocialMediaOverrides>()

  const iconDict: Record<SocialMedia, ReactElement> = {
    FACEBOOK: <FaFacebook />,
    INSTAGRAM: <FaInstagram />,
    LINKEDIN: <FaLinkedin />,
    THREADS: <FaThreads />,
    X: <FaX />,
    TIKTOK: <FaTiktok />,
    META: <FaMeta />
  }

  return (
    <VStack spacing={2} w="full" {...props}>
      <GrandHeading
        as="h2"
        containerProps={{
          my: 0
        }}
        fontSize={['lg', 'xl']}
        textAlign={{
          base: 'left',
          md: 'center'
        }}
        title={overrideText('Följ oss', overrides?.followUs)}
        width="full"
      />
      <HStack>
        {items.map(({ label, icon, href, ...itemProps }) => (
          <GrandNextLink
            _active={{
              transform: 'scale(0.85)'
            }}
            _hover={{ opacity: 0.75 }}
            aria-label={label}
            bg="brand.darkExtraLight"
            borderRadius="50%"
            color="text.default"
            fontSize="xl"
            isExternal
            key={label}
            p={2}
            shadow="none"
            {...{ href }}
            {...itemProps}>
            {iconDict[icon]}
          </GrandNextLink>
        ))}
      </HStack>
    </VStack>
  )
}

export default FooterSocialMedia
